import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [0,7,4,5,6,2,8];

export const dictionary = {
		"/(public)": [~19,[7]],
		"/(public)/account": [~20,[7]],
		"/(public)/account/activate": [~21,[7]],
		"/(public)/account/login": [~22,[7]],
		"/(public)/account/logout": [~23,[7]],
		"/(public)/account/reset": [~24,[7]],
		"/(app)/app": [~15,[4]],
		"/(app)/app/[type]": [~16,[4,5]],
		"/(app)/app/[type]/[chapter]/quiz": [~17,[4,5,6]],
		"/(app)/app/[type]/[chapter]/video": [~18,[4,5,6]],
		"/(public)/bedankt": [~25,[7]],
		"/(public)/bedankt/[token]": [~26,[7]],
		"/(admin)/beheer": [9,[2,3]],
		"/(admin)/beheer/klanten": [~10,[2,3]],
		"/(admin)/beheer/kortingen": [~11,[2,3]],
		"/(admin)/beheer/kortingen/[code]": [~12,[2,3]],
		"/(admin)/beheer/quiz": [~13,[2,3]],
		"/(admin)/beheer/quiz/[chapter]": [~14,[2,3]],
		"/(public)/blogs": [~27,[7]],
		"/(public)/blogs/[slug]": [~28,[7]],
		"/(public)/checkout/error": [30,[7]],
		"/(public)/checkout/[variant]": [~29,[7]],
		"/(public)/contact": [~31,[7]],
		"/(public)/contact/bedankt": [32,[7]],
		"/(public)/cookieverklaring": [~33,[7]],
		"/(public)/faq": [~34,[7]],
		"/fatal": [46],
		"/(public)/gratis-proefpakket": [~35,[7]],
		"/(public)/hoe-werkt-het": [~36,[7]],
		"/(public)/kies-je-pakket": [~37,[7]],
		"/(public)/kies-je-pakket/[type]": [~38,[7]],
		"/(public)/kies-je-pakket/[type]/reminder": [39,[7]],
		"/(user)/mijn-account": [~45,[8]],
		"/(public)/privacy": [~40,[7]],
		"/(public)/referral/[code]": [~41,[7]],
		"/(public)/reminder": [~42,[7]],
		"/(public)/reminder/bevestiging": [43,[7]],
		"/(public)/uitslag": [~44,[7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';